import {
  SHOW_RESET_FORM,
  HIDE_RESET_FORM,
  SHOW_RESET_MESSAGE,
  HIDE_RESET_MESSAGE,
} from './actions';

const defaultState = {
  showForm: false,
  showMessage: false,
};

export default (state = defaultState, { type }) => {
  switch (type) {
  case SHOW_RESET_FORM:
    return { ...state, showForm: true, showMessage: false };
  case HIDE_RESET_FORM:
    return { ...state, showForm: false };
  case SHOW_RESET_MESSAGE:
    return { ...state, showMessage: true };
  case HIDE_RESET_MESSAGE:
    return { ...state, showMessage: false };
  default:
    return state;
  }
};
