import axios from 'axios';

import { action, formDataToFetch } from '../utils';

export const SHOW_RESET_FORM = 'SHOW_RESET_FORM';
export const HIDE_RESET_FORM = 'HIDE_RESET_FORM';
export const SHOW_RESET_MESSAGE = 'SHOW_RESET_MESSAGE';
export const HIDE_RESET_MESSAGE = 'HIDE_RESET_MESSAGE';

export const SEND_RESET_EMAIL = 'SEND_RESET_EMAIL';

export const showResetForm = action(SHOW_RESET_FORM);

export const hideResetForm = action(HIDE_RESET_FORM);

export const showResetMessage = action(SHOW_RESET_MESSAGE);

export const hideResetMessage = action(HIDE_RESET_MESSAGE);

export const sendResetEmail = formData => dispatch => axios
  .post('/forgot', formDataToFetch(formData))
  .then(() => {
    dispatch(hideResetForm());
    dispatch(showResetMessage());
  })
  .catch(() => {
    dispatch(showResetForm());
    dispatch(hideResetMessage());
  });
