import axios from 'axios';

import { action, formDataToFetch } from '../utils';

import { showResetForm } from '../reset/actions';

export const START_LOADING = 'START_LOADING';

export const LOGIN_FAIL = 'LOGIN_FAIL';

export const startLoading = action(START_LOADING);

export const loginFail = action(LOGIN_FAIL);

export const login = ({ formData }) => (dispatch) => {
  dispatch(startLoading());

  axios
    .post('/login', formDataToFetch(formData))
    .then(() => {
      const urlParams = new URLSearchParams(window.location.search);
      window.location.href = urlParams.get('redirect') || '/';
    }).catch(() => {
      dispatch(loginFail());
      dispatch(showResetForm());
    });
};
