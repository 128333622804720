/* eslint-disable react/jsx-filename-extension */

import React from 'react';
import { useLocation } from 'react-router-dom';

import Reset from './reset/component';
import Form from './form/component';

const App = () => {
  const location = useLocation();
  const url = location.search;

  const query = new URLSearchParams(location.search);

  let error;
  switch (query?.get('error')) {
  case 'google':
    error = 'Google sign-in failed.';
    break;
  case 'microsoft':
    error = 'Microsoft sign-in failed';
    break;
  case 'account':
    error = 'You don\'t seem to have access to any organization.';
    break;
  default:
  }

  const fromAskem = url?.match('from_askem');

  return (
    <div>
      <div className="logo">
        <img src="/static/img/a-logomark.svg" alt="" />
      </div>
      <h1>Askem Classic</h1>
      <h2>
        <em>Log in</em>
        {' '}
        to see what's going on!
      </h2>
      <div className="login-wrapper" id="login-page">
        { error && (
          <p className="error-message" id="login-fail-message">
            {error}
          </p>
        )}
        {
          fromAskem && (
            <div className="from-askem-message">
              <i className="fa fa-info-circle" />
              <div>
                <p>You tried to login to Askem, but have a Askem Classic license.</p>
                <p>Please log in here instead!</p>
              </div>
            </div>
          )
        }
        <Form />
        <Reset />
      </div>
    </div>
  );
};

export default App;
