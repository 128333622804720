export const formDataToFetch = formData => {
    const data = new URLSearchParams();
    Array.from(formData).forEach((pair) => {
        data.append(pair[0], pair[1]);
    });

    return data;
};

export const action = type => () => ({ type });
