import { START_LOADING, LOGIN_FAIL } from './actions';

const defaultState = {
    isLoading: false,
    fail: false,
};

export default (state = defaultState, { type }) => {
    switch (type) {
    case START_LOADING:
        return { isLoading: true, fail: false };
    case LOGIN_FAIL:
        return { isLoading: false, fail: true };
    default:
        return state;
    }
};
