/* eslint-disable react/jsx-filename-extension */

import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { login } from './actions';

const Form = ({ login: _login, fail, isLoading }) => {
  const onSubmit = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const formData = new FormData(ev.target);

    _login({ formData });
  };
  const buttonClassName = `login ${isLoading ? 'loading' : ''}`;

  return (
    <div>
      <form onSubmit={onSubmit}>
        <input
          type="text"
          name="username"
          placeholder="E-mail or username"
          autoFocus
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
        />
        {fail ? (
          <p className="error-message" id="login-fail-message">
            Incorrect username or password
          </p>
        ) : null}
        <button className={buttonClassName} id="login" type="submit">
          <span>Log in</span>
          <span className="processing">
            <img src="/static/img/spinner.svg" alt="" />
            <span>Logging in</span>
          </span>
        </button>
        <div className="google-sign-in">
          <button type="button" className="google-sign-in-button" onClick={() => { window.location = '/auth/go/'; }} />
        </div>
        <div className="microsoft-sign-in">
          <button type="button" className="microsoft-sign-in-button" onClick={() => { window.location = '/auth/ms/'; }} />
        </div>
      </form>
    </div>
  );
};

export default connect(
  ({ form }) => form,
  dispatch => bindActionCreators({ login }, dispatch)
)(Form);
