/* eslint-disable react/jsx-filename-extension */

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { sendResetEmail, showResetForm } from './actions';

const Reset = ({
  sendResetEmail: _sendResetEmail,
  showResetForm: _showResetForm,
  showMessage,
  showForm,
}) => {
  const onSubmit = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const formData = new FormData(ev.target);

    _sendResetEmail(formData);
  };

  return (
    <div>
      <p className="forgot">
        <a
          id="show-reset"
          onClick={_showResetForm}
        >
          Forgot your password?
          {' '}
        </a>
      </p>
      <div
        className="reset"
        style={showForm ? { display: 'block' } : {}}
      >
        <p>
          Type your email address here and we'll send you a reset
          link.
        </p>
        <form onSubmit={onSubmit}>
          <input name="email" id="reset-password-input" required />
          <button
            className="login"
            id="reset-button"
            type="submit"
          >
            Send
          </button>
        </form>
      </div>
      { showMessage ? (
        <div className="reset-message" id="reset-message">
          <p>Password reset link has been sent to your email.</p>
        </div>
      ) : (
        null
      )}
    </div>
  );
};

export default connect(
  ({ reset }) => reset,
  dispatch => bindActionCreators({ sendResetEmail, showResetForm }, dispatch),
)(Reset);
