/* eslint-disable react/jsx-filename-extension */
import 'es6-symbol';
import 'url-search-params-polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../common/js/polyfills/form-data';

import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import { Provider } from 'react-redux';

import thunk from 'redux-thunk';

import {
  applyMiddleware, combineReducers, createStore, compose,
} from 'redux';

import formReducer from './form/reducers';
import resetReducer from './reset/reducers';

import App from './Wrapper';

import '../common/sass/login.sass';

const store = createStore(
  combineReducers({ form: formReducer, reset: resetReducer }),
  {},
  compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f,
  ),
);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path="/" component={App} />
        <Route path="/login/:error?" component={App} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root'),
);
